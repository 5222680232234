<template>
  <v-card>
    <div
      id="blurHelper"
      tabindex="-1"
      style="position: absolute; left: -9999px; top: -9999px"
    ></div>
    <v-container>
      <v-row>
        <v-col cols="7" class="pb-0">
          <v-card-title id="title" tabindex="-1" class="text-button">{{
            isMobile ? "Nueva receta" : "Crear receta electrónica"
          }}</v-card-title>
        </v-col>
        <v-col cols="5" class="pt-0 pr-0">
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-tooltip text="Cerrar" location="left">
              <template v-slot:activator="{ props }">
                <v-btn
                  color="primary"
                  v-bind="props"
                  :icon="closeIcon"
                  id="vBtnCloseCreateReceta"
                  @click="closeModal()"
                >
                </v-btn>
              </template>
            </v-tooltip>
          </v-card-actions>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-stepper v-model="step" :items="steps" hide-actions>
          <template v-slot:[`item.1`]>
            <v-card flat>
              <v-form v-model="isPacienteFormValid" ref="paciente-form"
                ><v-container :class="isMobile ? 'px-0 mx-0' : 'my-1'">
                  <v-row :no-gutters="isMobile">
                    <v-col :cols="isMobile ? 12 : 4" class="py-0">
                      <v-select
                        :items="financiadoresItems"
                        label="Financiador"
                        v-model="financiadorSelected"
                        autocomplete="off"
                        tabindex="0"
                        return-object
                        @update:model-value="setDataFinanciador()"
                        variant="outlined"
                        density="compact"
                        item-value="id"
                        item-title="value1"
                        id="FinanciadorSelect"
                        :rules="rules.required"
                      ></v-select>
                    </v-col>
                    <v-col :cols="isMobile ? 12 : 3" class="py-0">
                      <DatePicker
                        @selectFecha="selectFechaEmision($event)"
                        label="Fecha prescripción"
                        :minDate="minDateFechaEmisionFormatted"
                        :maxDate="maxDateFechaEmisionFormatted"
                        :disabled="false"
                        tabindex="1"
                        :dateMenu="menuFechaEmision"
                        :defaultDate="fechaEmision"
                        :rules="[
                          ...rules.required,
                          ...rules.validDateFormat,
                          financiadorSelected != null
                            ? rules.dateSelectedInRange(
                                fechaEmision,
                                minDateFechaEmision,
                                maxDateFechaEmision
                              )
                            : true,
                          rules.validDateRange(
                            selectedFechaNacimiento,
                            selectedFechaEmision
                          ),
                          rules.validDateRange(
                            formatDate(new Date()),
                            selectedFechaEmision
                          ),
                        ]"
                      />
                    </v-col>
                    <v-col
                      :cols="isMobile ? 12 : 5"
                      class="py-0"
                      v-if="
                        validaAfiliado != null &&
                        validaAfiliado &&
                        financiadorSelected != null
                      "
                    >
                      <v-autocomplete
                        label="Afiliado"
                        v-model="selectedAfiliado"
                        :items="afiliados"
                        autocomplete="off"
                        v-model:search="inputAfiliado"
                        ref="afiliados-autocomplete"
                        id="AfiliadoAutocomplete"
                        return-object
                        class="mr-2"
                        variant="outlined"
                        density="compact"
                        item-title="docId"
                        item-value="nroAfiliado"
                        :hint="
                          financiadorSelected != null &&
                          fechaEmision != null &&
                          selectedAfiliado == null
                            ? 'Búsqueda por DNI o N° afiliado'
                            : selectedAfiliado != null
                            ? ''
                            : 'Seleccionar fecha y financiador para buscar afiliado'
                        "
                        persistent-hint
                        :loading="loadingAfiliados"
                        :custom-filter="customFilterAfiliado"
                        :readonly="
                          selectedFechaEmision == null ||
                          financiadorSelected == null
                        "
                        clearable
                        :rules="validaAfiliado ? rules.required : []"
                        :menu-props="{ closeOnContentClick: true }"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item @click="setAfiliado(item)">
                            <v-list-item-title>{{
                              item.raw.benNombreCompleto
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                              >N° afiliado: {{ item.value }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle
                              >DNI: {{ item.raw.docId }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle
                              v-if="item.raw.estadoBenef != null"
                              >Estado: {{ item.raw.estadoBenef.value }}
                            </v-list-item-subtitle>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      :cols="isMobile ? 12 : 4"
                      class="py-0"
                      :class="isMobile ? 'pt-1' : ''"
                      v-if="
                        validaAfiliado != null &&
                        validaAfiliado &&
                        selectedAfiliado != null
                      "
                    >
                      <v-select
                        :items="planesItems"
                        label="Plan"
                        v-model="planSelected"
                        return-object
                        variant="outlined"
                        density="compact"
                        item-value="id"
                        :readonly="validaAfiliado"
                        item-title="value"
                        :rules="rules.required"
                      ></v-select>
                    </v-col>
                    <v-col
                      :cols="isMobile ? 10 : 3"
                      class="py-0"
                      :class="isMobile ? 'pt-1' : ''"
                      v-else-if="
                        validaAfiliado != null &&
                        !validaAfiliado &&
                        financiadorSelected != null &&
                        !afiliadoSearched &&
                        solicitudItem == null
                      "
                    >
                      <v-text-field
                        label="Buscar afiliado"
                        variant="outlined"
                        density="compact"
                        hint="Búsqueda por DNI o N° afiliado"
                        persistent-hint
                        :rules="rules.required"
                        v-model="searchAfiliadoField"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      :cols="isMobile ? 2 : 1"
                      class="py-0"
                      :class="isMobile ? 'pt-2 pl-1 pr-0' : ''"
                      v-if="
                        validaAfiliado != null &&
                        !validaAfiliado &&
                        financiadorSelected != null &&
                        !afiliadoSearched &&
                        solicitudItem == null
                      "
                    >
                      <v-tooltip text="Buscar afiliado" location="left">
                        <template v-slot:activator="{ props }">
                          <v-btn
                            color="primary"
                            :icon="searchIcon"
                            id="buscarAfiliadoBtn"
                            v-bind="props"
                            :loading="isLoadingSearchAfiliado"
                            :disabled="searchAfiliadoField == null"
                            @click="searchAfiliado()"
                            :size="isMobile ? 'x-small' : 'small'"
                          >
                          </v-btn>
                        </template>
                      </v-tooltip>
                    </v-col>
                    <v-col
                      :cols="isMobile ? 12 : 4"
                      class="py-0"
                      :class="isMobile ? 'pt-1' : ''"
                      v-if="
                        validaAfiliado != null &&
                        !validaAfiliado &&
                        financiadorSelected != null &&
                        afiliadoSearched
                      "
                    >
                      <v-text-field
                        label="Plan"
                        variant="outlined"
                        density="compact"
                        :rules="
                          rules.required.concat(
                            [rules.maxLength(planSelected, 50)],
                            rules.nameRulesWithNumber
                          )
                        "
                        v-model="planSelected"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      :cols="isMobile ? 12 : 3"
                      class="py-0"
                      v-if="
                        selectedAfiliado != null &&
                        validaAfiliado != null &&
                        validaAfiliado
                      "
                    >
                      <v-text-field
                        label="Apellido/s y nombre/s paciente"
                        variant="outlined"
                        density="compact"
                        :rules="
                          rules.required.concat(
                            [rules.maxLength(pacienteApellidoNombreText, 50)],
                            rules.nameRulesWithNumber
                          )
                        "
                        v-model="pacienteApellidoNombreText"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      :cols="isMobile ? 12 : !validaAfiliado ? 4 : 3"
                      class="py-0"
                      v-if="
                        financiadorSelected != null &&
                        validaAfiliado != null &&
                        !validaAfiliado &&
                        afiliadoSearched
                      "
                    >
                      <v-text-field
                        label="Apellido/s"
                        variant="outlined"
                        density="compact"
                        :rules="
                          rules.required.concat(
                            [rules.maxLength(pacienteApellido, 50)],
                            rules.nameRulesWithNumber
                          )
                        "
                        v-model="pacienteApellido"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      :cols="isMobile ? 12 : 3"
                      class="py-0"
                      v-if="
                        financiadorSelected != null &&
                        validaAfiliado != null &&
                        !validaAfiliado &&
                        afiliadoSearched
                      "
                    >
                      <v-text-field
                        label="Nombre/s"
                        variant="outlined"
                        density="compact"
                        :rules="
                          rules.required.concat(
                            [rules.maxLength(pacienteNombre, 50)],
                            rules.nameRulesWithNumber
                          )
                        "
                        v-model="pacienteNombre"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      :cols="isMobile ? 8 : 2"
                      class="py-0"
                      v-if="
                        (financiadorSelected != null &&
                          validaAfiliado != null &&
                          !validaAfiliado &&
                          afiliadoSearched) ||
                        selectedAfiliado != null
                      "
                    >
                      <v-select
                        :items="tiposDocItems"
                        label="Tipo documento"
                        v-model="tipoDocSelected"
                        @keydown.tab="focusComponent('textFieldDocumento', 10)"
                        return-object
                        variant="outlined"
                        density="compact"
                        item-value="id"
                        item-title="value1"
                        :rules="rules.required"
                        clearable
                      ></v-select>
                    </v-col>
                    <v-col
                      :cols="isMobile ? 12 : 3"
                      class="py-0"
                      v-if="
                        (financiadorSelected != null &&
                          validaAfiliado != null &&
                          !validaAfiliado &&
                          afiliadoSearched) ||
                        selectedAfiliado != null
                      "
                    >
                      <v-text-field
                        label="Número documento"
                        density="compact"
                        variant="outlined"
                        id="textFieldDocumento"
                        v-mask="['#.###.###', '##.###.###']"
                        :rules="rules.required"
                        v-model="pacienteDocumento"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      :cols="isMobile ? 12 : 3"
                      class="py-0"
                      v-if="
                        (financiadorSelected != null &&
                          validaAfiliado != null &&
                          !validaAfiliado &&
                          afiliadoSearched) ||
                        selectedAfiliado != null
                      "
                    >
                      <v-select
                        :items="generosItems"
                        label="Sexo"
                        v-model="generoSelected"
                        return-object
                        @keydown.tab="
                          focusComponent('textFieldNroAfiliado', 10)
                        "
                        variant="outlined"
                        density="compact"
                        item-value="id"
                        item-title="value"
                        :rules="rules.required"
                        clearable
                      ></v-select>
                    </v-col>
                    <v-col
                      :cols="isMobile ? 12 : 2"
                      class="py-0"
                      v-if="
                        (financiadorSelected != null &&
                          validaAfiliado != null &&
                          !validaAfiliado &&
                          afiliadoSearched) ||
                        selectedAfiliado != null
                      "
                    >
                      <v-text-field
                        label="Número afiliado"
                        density="compact"
                        variant="outlined"
                        id="textFieldNroAfiliado"
                        :readonly="validaAfiliado"
                        v-mask="'##############'"
                        v-model="pacienteNumero"
                        :rules="rules.required"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      :cols="isMobile ? 12 : 3"
                      class="py-0"
                      v-if="
                        (financiadorSelected != null &&
                          validaAfiliado != null &&
                          !validaAfiliado &&
                          afiliadoSearched) ||
                        selectedAfiliado != null
                      "
                    >
                      <DatePicker
                        @selectFecha="selectFechaNacimiento($event)"
                        label="Fecha nacimiento"
                        :minDate="minDateFechaNacimiento"
                        :maxDate="maxDateFechaNacimientoFormatted"
                        :disabled="false"
                        :dateMenu="menuFechaNacimiento"
                        :defaultDate="fechaNacimiento"
                        :rules="[
                          ...rules.required,
                          ...rules.validDateFormat,
                          rules.validDateRange(
                            selectedFechaNacimiento,
                            formatDate(new Date())
                          ),
                        ]"
                      />
                    </v-col>
                    <v-col
                      :cols="isMobile ? 12 : 4"
                      class="py-0"
                      v-if="
                        (financiadorSelected != null &&
                          validaAfiliado != null &&
                          !validaAfiliado &&
                          afiliadoSearched) ||
                        selectedAfiliado != null
                      "
                    >
                      <v-text-field
                        label="Email"
                        density="compact"
                        variant="outlined"
                        :rules="
                          rules.required.concat(
                            [rules.maxLength(pacienteEmail, 100)],
                            rules.required,
                            rules.emailRules
                          )
                        "
                        v-model="pacienteEmail"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      :cols="isMobile ? 12 : 3"
                      class="py-0 pr-0 mr-0"
                      v-if="
                        (financiadorSelected != null &&
                          validaAfiliado != null &&
                          !validaAfiliado &&
                          afiliadoSearched) ||
                        selectedAfiliado != null
                      "
                    >
                      <v-select
                        v-model="selectedPrefix"
                        :items="countryPrefixes"
                        label="Prefijo"
                        item-value="value"
                        item-title="text"
                        density="compact"
                        variant="outlined"
                        class="mr-0 pr-0"
                      ></v-select>
                    </v-col>
                    <v-col
                      :cols="isMobile ? 12 : 4"
                      class="py-0"
                      v-if="
                        (financiadorSelected != null &&
                          validaAfiliado != null &&
                          !validaAfiliado &&
                          afiliadoSearched) ||
                        selectedAfiliado != null
                      "
                    >
                      <v-text-field
                        label="Teléfono"
                        density="compact"
                        variant="outlined"
                        :rules="
                          rules.required.concat(
                            [rules.maxLength(pacienteTelefono, 50)],
                            rules.required,
                            rules.telephoneRules
                          )
                        "
                        :prefix="selectedPrefix"
                        v-model="pacienteTelefono"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      class="py-0"
                      :cols="isMobile ? 12 : 5"
                      v-if="
                        (financiadorSelected != null &&
                          validaAfiliado != null &&
                          !validaAfiliado &&
                          afiliadoSearched) ||
                        selectedAfiliado != null
                      "
                    >
                      <p class="to-right" style="color: red">
                        (i) Importante: verifique que los datos ingresados sean
                        correctos.
                      </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card>
          </template>

          <template v-slot:[`item.2`]>
            <v-card flat
              ><v-form v-model="isPrescripcionFormValid" ref="prescripcion-form"
                ><v-container :class="isMobile ? 'px-0' : ''">
                  <v-row :no-gutters="isMobile">
                    <v-col cols="12" class="pb-0">
                      <v-textarea
                        v-model.trim="diagnostico"
                        label="Diagnóstico"
                        variant="outlined"
                        id="diagnosticoAutocomplete"
                        autocomplete="off"
                        density="compact"
                        @keydown.tab="focusComponent('buscarMedBtn', 10)"
                        auto-grow
                        :rules="
                          rules.required.concat([
                            rules.maxLength(diagnostico, 200),
                          ])
                        "
                        rows="1"
                      ></v-textarea>
                    </v-col>
                    <v-divider v-if="!isMobile"></v-divider>
                    <v-col :cols="isMobile ? 10 : 6" class="pb-0">
                      <v-select
                        label="Medicamento"
                        v-model="selectedMedicamento"
                        :items="medicamentos"
                        return-object
                        autocomplete="off"
                        class="mr-2"
                        variant="outlined"
                        density="compact"
                        item-title="nombrePresentacion"
                        item-value="id"
                        clearable
                        readonly
                      >
                      </v-select>
                    </v-col>
                    <v-col :cols="isMobile ? 2 : 1" class="pb-0">
                      <v-tooltip text="Buscar medicamentos" location="left">
                        <template v-slot:activator="{ props }">
                          <v-btn
                            color="primary"
                            :icon="searchIcon"
                            id="buscarMedBtn"
                            v-bind="props"
                            @click="openModalMedicamentos()"
                            :size="isMobile ? 'x-small' : 'small'"
                          >
                          </v-btn>
                        </template>
                      </v-tooltip>
                    </v-col>
                    <v-col
                      :cols="isMobile ? 8 : 2"
                      class="pb-0"
                      :class="isMobile ? 'pt-1 pb-1' : ''"
                    >
                      <v-text-field
                        :label="isMobile ? 'Cantidad' : 'Cantidad'"
                        variant="outlined"
                        density="compact"
                        min="1"
                        class="center-input"
                        id="cantidadMed"
                        type="number"
                        v-model="medicamentoCantidad"
                        :rules="rules.required.concat(rules.validNumberRule99)"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <v-textarea
                        v-model="posologia"
                        label="Posología"
                        variant="outlined"
                        density="compact"
                        auto-grow
                        rows="1"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <v-btn
                        density="compact"
                        color="primary"
                        class="to-right"
                        @click="addMedicamento()"
                        :disabled="
                          selectedMedicamento == null ||
                          medicamentoCantidad <= 0
                        "
                        >Agregar</v-btn
                      >
                    </v-col>
                    <v-col cols="12" :class="isMobile ? 'pb-0 pt-3' : 'py-0'">
                      <v-data-table
                        :items="medicamentosAgregados"
                        item-value="id"
                        :mobile="isMobile"
                        :hide-default-header="isMobile"
                        :hide-no-data="isMobile"
                        density="compact"
                        :headers="headersMedicamentosAgregados"
                      >
                        <template v-slot:[`item.regNom`]="{ item }">
                          <span :style="isMobile ? 'font-size: 10px' : ''">
                            {{
                              (item.regNom ?? "").length > 65
                                ? item.regNom.substring(0, 62) + "..."
                                : item.regNom ?? ""
                            }}</span
                          ></template
                        >
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-tooltip text="Editar medicamento" location="start">
                            <template v-slot:activator="{ props }">
                              <v-icon
                                size="20"
                                @click="editMedicamento(item)"
                                @keydown.enter="editMedicamento(item)"
                                v-bind="props"
                              >
                                {{ editIcon }}
                              </v-icon>
                            </template>
                          </v-tooltip>
                          <v-tooltip
                            text="Eliminar medicamento"
                            location="start"
                          >
                            <template v-slot:activator="{ props }">
                              <v-icon
                                size="20"
                                v-bind="props"
                                @click="deleteMedicamento(item)"
                                @keydown.enter="deleteMedicamento(item)"
                              >
                                {{ deleteIcon }}
                              </v-icon>
                            </template>
                          </v-tooltip>
                        </template>
                        <template #bottom></template>
                      </v-data-table>
                    </v-col>
                  </v-row> </v-container
              ></v-form>
            </v-card>
          </template>
          <template v-slot:[`item.3`]>
            <v-card flat>
              <v-container :class="isMobile ? 'px-0' : ''">
                <v-row>
                  <v-col :cols="isMobile ? 12 : 3" class="pb-0">
                    <v-text-field
                      label="Fecha prescripción"
                      variant="outlined"
                      density="compact"
                      style="color: #000"
                      v-model="selectedFechaEmision"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="isMobile ? 12 : 4" class="pb-0">
                    <v-text-field
                      label="Apellido/s y nombre/s paciente"
                      variant="outlined"
                      density="compact"
                      v-model="pacienteApellidoNombreText"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="isMobile ? 12 : 5" class="pb-0">
                    <v-text-field
                      label="Email"
                      variant="outlined"
                      density="compact"
                      v-model="pacienteEmail"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="isMobile ? 12 : 3" class="py-0">
                    <v-text-field
                      label="Teléfono"
                      variant="outlined"
                      density="compact"
                      v-model="pacienteTelefono"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="isMobile ? 12 : 3" class="py-0">
                    <v-text-field
                      label="Tipo documento"
                      variant="outlined"
                      density="compact"
                      v-model="tipoDocSelected.value1"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="isMobile ? 12 : 3" class="py-0">
                    <v-text-field
                      label="N° documento"
                      variant="outlined"
                      density="compact"
                      v-model="pacienteDocumento"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="isMobile ? 12 : 3" class="py-0">
                    <v-text-field
                      label="Sexo"
                      variant="outlined"
                      density="compact"
                      v-model="generoSelected.value"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="isMobile ? 12 : 3" class="py-0">
                    <v-text-field
                      label="Fecha nacimiento"
                      variant="outlined"
                      density="compact"
                      v-model="selectedFechaNacimiento"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="isMobile ? 12 : 4" class="py-0">
                    <v-text-field
                      label="Financiador"
                      variant="outlined"
                      density="compact"
                      v-model="financiadorSelected.value1"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col
                    :cols="isMobile ? 12 : 3"
                    class="py-0"
                    v-if="validaAfiliado"
                  >
                    <v-text-field
                      label="Plan"
                      variant="outlined"
                      density="compact"
                      v-model="planSelected.value"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="isMobile ? 12 : 3" class="py-0" v-else>
                    <v-text-field
                      label="Plan"
                      variant="outlined"
                      density="compact"
                      v-model="planSelected"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="isMobile ? 12 : 2" class="py-0">
                    <v-text-field
                      label="N° afiliado"
                      variant="outlined"
                      density="compact"
                      v-model="pacienteNumero"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-textarea
                      v-model="diagnostico"
                      label="Diagnósitco"
                      variant="outlined"
                      density="compact"
                      auto-grow
                      rows="1"
                      readonly
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-data-table
                      :mobile="isMobile"
                      :hide-default-header="isMobile"
                      :hide-no-data="isMobile"
                      :items="medicamentosAgregados"
                      item-value="id"
                      density="compact"
                      :headers="vistaPreviaHeadersMedicamentosAgregados"
                    >
                      <template #bottom></template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </template>
        </v-stepper>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6" class="py-0">
            <v-btn
              @click="prevStep"
              color="red"
              id="atrasBtn"
              :disabled="step == 1"
              >{{ previousText }}</v-btn
            >
          </v-col>
          <v-col cols="6" class="py-0">
            <v-btn
              class="to-right"
              @click="nextStep"
              id="siguienteBtn"
              color="primary"
              @keydown.tab="focusComponent('vBtnCloseCreateReceta')"
              :loading="loadingCreateRecipeBtn"
              :disabled="
                !isPacienteFormValid ||
                !afiliadoSearched ||
                !isPrescripcionFormValid ||
                (medicamentosAgregados.length == 0 &&
                  diagnostico != null &&
                  step == 2)
              "
              >{{ nextText }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
      <v-dialog
        v-if="dialogSendRecipe"
        v-model="dialogSendRecipe"
        :max-width="isMobile ? '95%' : '35%'"
        persistent
      >
        <SendRecipe
          :link="recipeLink"
          :email="pacienteEmail"
          :telefono="selectedPrefix + pacienteTelefono"
          :paciente="pacienteApellidoNombreText"
          @closeModal="closeModal()"
        />
      </v-dialog>
      <v-dialog
        v-if="dialogBusquedaMedicamentos"
        v-model="dialogBusquedaMedicamentos"
        :max-width="isMobile ? '95%' : '70%'"
        persistent
      >
        <BusquedaAvanzadaMedicamentos
          :financiadorId="financiadorSelected.id"
          :osId="
            validaAfiliado && validaCobertura ? selectedAfiliado.osId : null
          "
          :planId="
            validaAfiliado && validaCobertura ? selectedAfiliado.planId : null
          "
          :fecha="validaAfiliado && validaCobertura ? fechaEmision : null"
          :benId="
            validaAfiliado && validaCobertura ? selectedAfiliado.benId : null
          "
          @closeModalMedicamentos="closeModalMedicamentos()"
          @selectMedicamento="selectMedicamento($event)"
        />
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index";
import DatePicker from "@/components/shared/DatePicker.vue";
import helperDate from "@/utils/helpers/dateTimePicker";
import { mapActions, mapGetters } from "vuex";
import rules from "@/utils/helpers/rules";
import SendRecipe from "@/components/modules/solicitudes/SendRecipe.vue";
import BusquedaAvanzadaMedicamentos from "@/components/shared/BusquedaAvanzadaMedicamentos.vue";
import { focusComponent } from "@/utils/helpers/htmlHelpers";

export default {
  name: "CreateRecetaElectronica",
  components: {
    DatePicker,
    SendRecipe,
    BusquedaAvanzadaMedicamentos,
  },
  props: {
    solicitudItem: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      step: 1,
      rules: rules,
      steps: ["Datos del paciente", "Prescripción", "Vista previa"],
      completedSteps: [],
      closeIcon: enums.icons.CLOSE_ICON,
      addIcon: enums.icons.ADD_ICON,
      pacienteApellido: null,
      pacienteNombre: null,
      tiposDocItems: [],
      tipoDocSelected: null,
      pacienteDocumento: null,
      generosItems: [],
      generoSelected: null,
      menuFechaEmision: false,
      fechaEmision: null,
      selectedFechaEmision: null,
      menuFechaNacimiento: false,
      fechaNacimiento: null,
      selectedFechaNacimiento: null,
      minDateFechaNacimiento: "1910",
      minDateFechaEmision: new Date().setHours(0, 0, 0, 0),
      minDateFechaEmisionFormatted: helperDate.convertDateFormat(
        helperDate.formatDate(new Date())
      ),

      maxDateFechaEmisionFormatted: null,
      maxDateFechaEmision: null,
      maxDateFechaNacimiento: null,
      maxDateFechaNacimientoFormatted: null,
      pacienteNumero: null,
      financiadoresItems: [],
      financiadorSelected: null,
      planesItems: [],
      planSelected: null,
      pacienteEmail: null,
      pacienteTelefono: null,
      previousText: "Volver",
      nextText: "Siguiente",
      prestadorApellidoNombre: null,
      provinciasItems: [],
      provinciaSelected: null,
      prestadorMatricula: null,
      selectedPrestador: null,
      prestadores: [],
      loadingPrestadores: false,
      selectedAfiliado: null,
      inputAfiliado: "",
      afiliados: [],
      loadingAfiliados: false,
      diagnostico: null,
      selectedMedicamento: null,
      medicamentos: [],
      loadingMedicamentos: false,
      medicamentoCantidad: 1,
      posologia: null,
      medicamentosAgregados: [],
      headersMedicamentosAgregados: [
        { title: "Nombre", sortable: false, key: "regNom" },
        { title: "Cantidad", align: "end", sortable: false, key: "cantidad" },
        { title: "Posología", sortable: false, key: "posologia" },
        { title: "Acciones", align: "end", sortable: false, key: "actions" },
      ],
      vistaPreviaHeadersMedicamentosAgregados: [
        { title: "Nombre", sortable: false, key: "regNom" },
        { title: "Cantidad", sortable: false, key: "cantidad" },
        { title: "Posología", sortable: false, key: "posologia" },
      ],
      editIcon: enums.icons.EDIT_ICON,
      deleteIcon: enums.icons.DELETE_ICON,
      isPacienteFormValid: false,
      isPrescripcionFormValid: true,
      datosContacto: "",
      emailContacto: "",
      nombreConsultorio: "",
      direccionConsultorio: "",
      leyenda: "",
      imprimirDiagnostico: "",
      editMedicamentoAgregado: false,
      loadingCreateRecipeBtn: false,
      dialogSendRecipe: false,
      recipeLink: null,
      selectedPrefix: "+54",
      countryPrefixes: enums.enums.COUNTRYS_PREFIXES,
      afiliadoWithUploadedData: false,
      dialogBusquedaMedicamentos: false,
      searchIcon: enums.icons.SEARCH_ICON,
      validaAfiliado: null,
      validaCobertura: false,
      searchAfiliadoField: null,
      afiliadoSearched: false,
      isLoadingSearchAfiliado: false,
      alertMedicamentosDisplayed: false,
      pacienteApellidoNombreText: null
    };
  },
  async created() {
    this.setDefaultDates();
    await this.setSelects();
    if (this.solicitudItem != null) this.setRecetaToCopy();
  },
  mounted() {},
  watch: {
    selectedFechaEmision(val) {
      if (
        this.financiadorSelected !== null &&
        this.selectedAfiliado !== null &&
        val !== null &&
        val !== undefined
      ) {
        this.findAfiliado(); //volvemos a buscar el afiliado para verificar cobertura
      }
    },
    inputAfiliado(val) {
      if ((val?.trim() && val?.trim().length >= 3) || this.selectedAfiliado) {
        this.findAfiliado();
      } else {
        this.afiliados = [];
      }
    },
  },
  computed: {
    ...mapGetters({
      isMobile: "menu/isMobile",
    }),
  },
  methods: {
    ...mapActions({
      getFinanciadoresByUser: "taxonomy/getFinanciadoresByUser",
      getPlanesByFinanciador: "taxonomy/getPlanesByFinanciador",
      getTiposDocumentos: "taxonomy/getTiposDocumentos",
      getTiposDocumentoByFinanciador: "taxonomy/getTiposDocumentoByFinanciador",
      fetchPrestadoresByQuery: "taxonomy/fetchPrestadoresByQuery",
      getProvincias: "taxonomy/getProvincias",
      getMatriculas: "taxonomy/getMatriculas",
      getAfiliadoByNroAndFinanciador: "taxonomy/getAfiliadoByNroAndFinanciador",
      fetchAfiliadosFinanciadorByQuery:
        "taxonomy/fetchAfiliadosFinanciadorByQuery",
      createRecetaElectronica: "solicitud/createRecetaElectronica",
      getPrestadorDataByUserIdAndFinanciadorId:
        "solicitud/getPrestadorDataByUserIdAndFinanciadorId",
      getValidationsFinanciador: "financiador/getValidations",
      setAlert: "user/setAlert",
    }),
    focusComponent(refName, timeout) {
      focusComponent(refName, timeout);
    },
    pacienteApellidoNombre() {
      return `${this.pacienteApellido} ${this.pacienteNombre}`;
    },
    async setSelects() {
      const financiadores = await this.getFinanciadoresByUser();
      this.financiadoresItems = financiadores;
      if (this.financiadoresItems.length === 1) {
        this.financiadorSelected = financiadores[0];
        setTimeout(() => {
          const elementAfiliado = document.getElementById(
            "AfiliadoAutocomplete"
          );
          if (elementAfiliado) elementAfiliado.focus();
        }, 200);
        this.setDataFinanciador();
      } else {
        if (this.solicitudItem == null) {
          const selectComponent = document.getElementById("FinanciadorSelect");
          if (selectComponent) selectComponent.focus();
        }
      }
      this.generosItems = [
        { id: "M", value: "MASCULINO" },
        { id: "F", value: "FEMENINO" },
      ];
      const provincias = await this.getProvincias();
      this.provinciasItems = provincias;
    },
    async loadTiposDoc() {
      if (this.validaAfiliado) {
        const tiposDoc = await this.getTiposDocumentoByFinanciador({
          financiadorId: this.financiadorSelected?.id,
        });
        this.tiposDocItems = tiposDoc;
      } else {
        const tiposDoc = await this.getTiposDocumentos();
        this.tiposDocItems = tiposDoc;
      }
    },
    setDefaultDates() {
      const currentDate = new Date();
      this.fechaEmision = currentDate;
      this.selectedFechaEmision = this.formatDate(currentDate);
      this.fechaNacimiento = currentDate;
      this.selectedFechaNacimiento = this.formatDate(currentDate);
      this.maxDateFechaNacimientoFormatted = helperDate.convertDateFormat(
        this.formatDate(currentDate)
      );
    },
    async setRecetaToCopy() {
      this.financiadorSelected = this.financiadoresItems.find(
        (x) =>
          x.id == this.solicitudItem.receta.paciente.cobertura.nroFinanciador
      );
      await this.setDataFinanciador();
      if (this.validaAfiliado)
        this.inputAfiliado = this.solicitudItem.receta.paciente.nroDoc;
      else {
        this.afiliadoSearched = true;
        this.planSelected = this.solicitudItem.receta.paciente.cobertura.plan;
        this.pacienteApellido = this.solicitudItem.receta.paciente.apellido;
        this.pacienteNombre = this.solicitudItem.receta.paciente.nombre;
        this.pacienteApellidoNombreText = this.pacienteApellidoNombre();
        this.tipoDocSelected = this.tiposDocItems.find(
          (x) => x.value1 == this.solicitudItem.receta.paciente.tipoDoc
        );
        this.pacienteDocumento = this.solicitudItem.receta.paciente.nroDoc;
        this.generoSelected = this.generosItems.find(
          (x) => x.id == this.solicitudItem.receta.paciente.sexo
        );
        this.pacienteNumero =
          this.solicitudItem.receta.paciente.cobertura.numero;
        this.fechaNacimiento = new Date(
          this.solicitudItem.receta.paciente.fechaNacimiento
        );
        this.selectedFechaNacimiento = this.formatDate(
          new Date(this.solicitudItem.receta.paciente.fechaNacimiento)
        );
        this.pacienteEmail = this.solicitudItem.receta.paciente.email;
        this.pacienteTelefono = this.solicitudItem.receta.paciente.telefono;
      }
      this.diagnostico = this.solicitudItem.receta.diagnostico;
      this.afiliadoWithUploadedData = true;

      this.medicamentosAgregados =
        this.solicitudItem.receta.medicamentos.filter(
          (med) =>
            !this.solicitudItem.medicamentosBaja.some(
              (baja) => baja.regNo === med.regNo
            )
        );

      this.medicamentosAgregados.forEach((x) => {
        this.medicamentos.push({
          id: x.regNo,
          nombrePresentacion: x.regNom,
        });
      });
    },
    selectFechaEmision(event) {
      this.selectedFechaEmision = event.dateString;
      this.fechaEmision = event.date;
      if (this.financiadorSelected != null && this.selectedAfiliado != null) {
        this.afiliadoWithUploadedData = true;
        this.setAfiliado(this.selectedAfiliado);
      }
    },
    selectFechaNacimiento(event) {
      this.selectedFechaNacimiento = event.dateString;
      this.fechaNacimiento = event.date;
    },
    async setDataFinanciador() {
      this.afiliadoSearched = false;
      this.selectedAfiliado =
        this.searchAfiliadoField =
        this.inputAfiliado =
        this.planSelected =
        this.pacienteApellido =
        this.pacienteApellidoNombreText =
        this.pacienteNombre =
        this.tipoDocSelected =
        this.pacienteDocumento =
        this.generoSelected =
        this.pacienteNumero =
        this.fechaNacimiento =
        this.selectedFechaNacimiento =
        this.pacienteEmail =
        this.pacienteTelefono =
          null;
      this.setDefaultDates();
      try {
        await this.loadValidationsFinanciador();
        await this.loadPrestadorData();
        if (this.validaAfiliado) {
          await this.loadPlanes();
        }
        await this.loadTiposDoc();
      } catch (error) {
        console.error(error);
      }
    },
    async loadValidationsFinanciador() {
      if (this.financiadorSelected != null) {
        try {
          const validations = await this.getValidationsFinanciador(
            this.financiadorSelected?.id
          );
          // Valida afiliado
          this.validaAfiliado = validations.validaAfiliado;
          // Días emisión futuro
          let futureDate = new Date();
          futureDate.setDate(
            futureDate.getDate() + (validations.diasEmisionFuturo - 1)
          );
          this.maxDateFechaEmisionFormatted = helperDate.convertDateFormat(
            helperDate.formatDate(futureDate)
          );
          this.maxDateFechaEmision = futureDate.setHours(0, 0, 0, 0);
          // Valida cobertura
          this.validaCobertura = validations.validaCobertura;
        } catch (error) {
          console.error(error);
        }
      }
    },
    async loadPrestadorData() {
      if (this.financiadorSelected != null) {
        const prestadorData =
          await this.getPrestadorDataByUserIdAndFinanciadorId(
            this.financiadorSelected?.id
          );
        this.prestadorMatricula = prestadorData.matp.toString();
        this.prestadorApellidoNombre = prestadorData.nombre;
      }
    },
    async loadPlanes() {
      if (this.financiadorSelected != null) {
        const planes = await this.getPlanesByFinanciador({
          financiadorId: this.financiadorSelected?.id,
          fecha: this.selectedFechaEmision,
        });
        this.planesItems = planes;
      } else {
        this.planesItems = [];
        this.planSelected = null;
      }
    },
    selectMedicamento(medicamento) {
      this.medicamentos.push(medicamento);
      this.selectedMedicamento = medicamento;
      this.dialogBusquedaMedicamentos = false;
      focusComponent("cantidadMed", 100);
    },
    async findAfiliado() {
      if (this.timerAfiliado) {
        clearTimeout(this.timerAfiliado);
        this.timerAfiliado = null;
      }
      this.timerAfiliado = setTimeout(async () => {
        if (this.afiliados?.some((x) => x.title == this.inputAfiliado)) {
          return;
        } else if (!this.afiliadoWithUploadedData) {
          this.loadingAfiliados = true;
          const response = await this.fetchAfiliadosFinanciadorByQuery({
            fecha: this.selectedFechaEmision,
            input: this.inputAfiliado.toString().trim(),
            financiadorId: this.financiadorSelected?.id,
          });
          this.$refs["afiliados-autocomplete"].cachedItems = [];
          this.afiliados = response;
          this.loadingAfiliados = false;
          if (this.selectedAfiliado) {
            this.selectedAfiliado = response[0];
            this.setsAlertsAfiliado(this.selectedAfiliado);
          }
        } else {
          this.loadingAfiliados = true;
          const response = await this.fetchAfiliadosFinanciadorByQuery({
            fecha: this.selectedFechaEmision,
            input: this.inputAfiliado.toString().trim(),
            financiadorId: this.financiadorSelected?.id,
          });
          this.$refs["afiliados-autocomplete"].cachedItems = [];
          this.afiliados = response;
          this.setAfiliado(response[0]);
          this.loadingAfiliados = this.afiliadoWithUploadedData = false;
        }
      }, 1000);
    },
    setsAlertsAfiliado(selectedAfiliado) {
      switch (selectedAfiliado.estadoBenef.id) {
        case 1:
          this.setAlert({
            type: "success",
            message: "El afiliado posee cobertura.",
          });
          break;
        case 2:
          this.setAlert({
            type: "warning",
            message: "El afiliado se encuentra sin cobertura por baja.",
          });
          break;
        case 3:
          this.setAlert({
            type: "warning",
            message: "El afiliado se encuentra momentaneamente sin cobertura.",
          });
          break;
        case 4:
          this.setAlert({
            type: "warning",
            message:
              "El afiliado se encuentra momentaneamente sin cobertura, en gestión judicial.",
          });
          break;
        default:
          this.setAlert({
            type: "warning",
            message: "El afiliado se encuentra sin cobertura.",
          });
          break;
      }
    },
    setAfiliado(afiliado) {
      this.afiliadoSearched = true;
      if (this.afiliadoWithUploadedData) this.selectedAfiliado = afiliado;
      else this.selectedAfiliado = afiliado.raw;
      this.setsAlertsAfiliado(this.selectedAfiliado);

      this.planSelected = this.planesItems.find(
        (x) => x.id == this.selectedAfiliado.planId
      );
      this.pacienteApellido = this.selectedAfiliado.benApe;
      this.pacienteNombre = this.selectedAfiliado.benNom;
      this.pacienteApellidoNombreText = this.pacienteApellidoNombre();
      this.tipoDocSelected = this.tiposDocItems.find(
        (x) => x.id == this.selectedAfiliado.tipoDocId
      );
      this.pacienteDocumento = this.selectedAfiliado.docId;
      this.generoSelected = this.generosItems.find(
        (x) => x.id == this.selectedAfiliado.genero
      );
      this.pacienteNumero = this.selectedAfiliado.nroAfiliado;
      this.pacienteEmail = this.selectedAfiliado.email;
      this.pacienteTelefono = this.selectedAfiliado.telefono;

      this.selectedFechaNacimiento = this.selectedAfiliado.fechaNacimiento;
      const partesFecha = this.selectedFechaNacimiento.split("/");
      const fecha = new Date(
        partesFecha[2],
        partesFecha[1] - 1,
        partesFecha[0]
      );
      this.fechaNacimiento = fecha;

      this.inputAfiliado = this.selectedAfiliado.docId.toString();

      this.$refs["paciente-form"].validate();

      this.seleccionarSiguiente();
    },
    async searchAfiliado() {
      this.isLoadingSearchAfiliado = true;
      const data = {
        numero: this.searchAfiliadoField,
        financiadorId: this.financiadorSelected?.id,
      };
      try {
        const response = await this.getAfiliadoByNroAndFinanciador(data);
        if (response != null) {
          this.pacienteApellido = response.benApe;
          (this.pacienteNombre = response.benNom),
            (this.pacienteDocumento = response.docId);
          this.pacienteEmail = response.email;
          this.pacienteApellidoNombreText = this.pacienteApellidoNombre()
          this.fechaNacimiento = new Date(
            Date.parse(response.fechaNacimiento + "T00:00:00")
          );
          this.selectedFechaNacimiento = this.formatDate(this.fechaNacimiento);
          this.generoSelected = this.generosItems.find(
            (x) => x.id == response.genero
          );
          this.pacienteNumero = response.nroAfiliado;
          this.planSelected = response.plan;
          this.pacienteTelefono = response.telefono;
          this.tipoDocSelected = this.tiposDocItems.find(
            (x) => x.value1 == response.tipoDoc
          );
          this.setAlert({
            type: "success",
            message:
              "El afiliado ha sido encontrado en el sistema. A continuación se cargarán sus respectivos datos, por favor verificar los mismos.",
          });
        } else {
          this.setAlert({
            type: "info",
            message:
              "El afiliado no ha sido encontrado en el sistema. Cargue sus datos para continuar con la prescripción.",
          });
        }
        this.afiliadoSearched = true;
      } catch (error) {
        console.error(error);
      }
      this.isLoadingSearchAfiliado = false;
    },
    seleccionarSiguiente() {
      setTimeout(() => {
        let blurHelper = document.getElementById("blurHelper");
        blurHelper.focus();
        let componente = document.getElementById("siguienteBtn");
        componente.focus();
      }, 200);
    },
    customFilterAfiliado(item) {
      return item.nroAfiliado || item.benNombreCompleto || item.docId;
    },
    addMedicamento() {
      if (this.medicamentosAgregados.length === 3) {
        this.setAlert({
          type: "warning",
          message:
            "No es posible agregar más de 3(tres) medicamentos en una misma receta.",
        });
        return;
      }
      if (
        this.medicamentosAgregados.some(
          (x) => x.regNo == this.selectedMedicamento.id
        )
      ) {
        this.setAlert({
          type: "warning",
          message: "No es posible agregar el mismo medicamento dos veces.",
        });
        return;
      }
      this.medicamentosAgregados.push({
        regNo: this.selectedMedicamento.id,
        regNom: this.selectedMedicamento.nombrePresentacion,
        cantidad: Math.trunc(this.medicamentoCantidad),
        posologia: this.posologia,
      });
      this.posologia = this.selectedMedicamento = null;
      this.editMedicamentoAgregado = false;
    },
    editMedicamento(item) {
      this.editMedicamentoAgregado = true;
      this.medicamentosAgregados = this.medicamentosAgregados.filter(
        (x) => x.regNo !== item.regNo
      );
      this.medicamentoCantidad = item.cantidad;
      this.selectedMedicamento = this.medicamentos.find(
        (x) => x.id == item.regNo
      );
      this.posologia = item.posologia;
      this.focusComponent("buscarMedBtn", 10);
    },
    deleteMedicamento(item) {
      this.medicamentosAgregados = this.medicamentosAgregados.filter(
        (x) => x.regNo !== item.regNo
      );
      this.focusComponent("buscarMedBtn", 10);
    },
    formatDate(date) {
      return helperDate.formatDate(date);
    },
    nextStep() {
      if (this.step < this.steps.length) {
        this.step++;
        switch (this.step) {
          case 2:
            if (this.solicitudItem?.medicamentosBaja.length ?? 0 > 0) {
              if (!this.alertMedicamentosDisplayed) {
                this.setAlert({
                  type: "warning",
                  message: `Se eliminará/n el/los medicamento/s: '${this.solicitudItem.medicamentosBaja 
                    .map((mb) => mb.regNom)
                    .join(", ")}' ya que se encuentra/n dado/s de baja.`,
                  timeout: 10000,
                });
              }
              this.alertMedicamentosDisplayed = true;
            }

            if (
              this.diagnostico != null &&
              this.medicamentosAgregados.length > 0
            )
              this.isPrescripcionFormValid = true;
            else this.isPrescripcionFormValid = false;
            //vuelvo a setear la fecha porque se ponia undefined
            this.selectedFechaNacimiento = this.formatDate(
              this.fechaNacimiento
            );
            setTimeout(() => {
              const diagnosticoAutocomplete = document.getElementById(
                "diagnosticoAutocomplete"
              );
              diagnosticoAutocomplete.focus();
            }, 200);

            break;
          default:
            break;
        }
        if (this.step === this.steps.length) {
          this.nextText = "Finalizar";
          focusComponent("siguienteBtn");
        }
      } else this.createRecipe();
    },
    prevStep() {
      if (this.step == 3) this.nextText = "Siguiente";
      if (this.step > 0) {
        this.step--;
        switch (this.step) {
          case 1:
            this.isPrescripcionFormValid = true;
            break;
          default:
            break;
        }
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    closeModalMedicamentos() {
      this.dialogBusquedaMedicamentos = false;
    },
    openModalMedicamentos() {
      this.dialogBusquedaMedicamentos = true;
    },
    async createRecipe() {
      this.loadingCreateRecipeBtn = true;
      const data = {
        medicamentos: this.medicamentosAgregados,
        paciente: {
          apellido: this.pacienteApellido,
          nombre: this.pacienteNombre,
          apellidoNombre: this.pacienteApellidoNombreText,
          tipoDoc: this.tipoDocSelected.value1,
          nroDoc: this.pacienteDocumento.replaceAll(".", ""),
          sexo: this.generoSelected.id,
          fechaNacimiento: this.fechaNacimiento,
          localidad: "",
          provincia: "",
          email: this.pacienteEmail,
          telefono: this.pacienteTelefono,
          pais: "",
          cobertura: {
            nroFinanciador: this.financiadorSelected.id.toString(),
            plan: this.validaAfiliado
              ? this.planSelected.value
              : this.planSelected,
            numero: this.pacienteNumero,
            privado: true,
          },
        },
        medico: {
          matricula: {
            tipo: "mp",
            numero: this.prestadorMatricula,
            provincia: "",
          },
          apellido: this.prestadorApellidoNombre,
          nombre: "",
        },
        diagnostico: this.diagnostico,
        horario: "",
        diasAtencion: "",
        datosContacto: this.datosContacto,
        nombreConsultorio: this.nombreConsultorio,
        direccionConsultorio: this.direccionConsultorio,
        email: this.emailContacto,
        informacionAdicional: "",
        clienteAppId: null,
        imprimirDiagnostico: "S",
        fechaEmision: this.fechaEmision,
      };
      try {
        const response = await this.createRecetaElectronica(data);
        if (response.status === 200) {
          this.setAlert({
            type: "success",
            message: "Receta creada con éxito.",
          });
          this.recipeLink = response.data.link;
          this.dialogSendRecipe = true;
        }
      } catch (error) {
        console.error(error);
      }
      this.loadingCreateRecipeBtn = false;
    },
  },
};
</script>

<style scoped>
.center-input >>> input {
  text-align: center;
}
</style>
