<template>
  <v-container>
    <PageHeader :title="pageTitle" :path="goToPath" />
    <!-- campos de busqueda -->
    <v-card>
      <v-container
        class="pt-2"
        :class="{ 'pb-0': !showFilters, 'pb-4': showFilters }"
      >
        <v-card-actions
          v-if="filtersApplied && filtersApplied.length > 0 && !showFilters"
        >
          <v-row :no-gutters="isMobile">
            <v-col v-if="!isMobile" cols="11">
              <filters-selected
                :filtersApplied="filtersApplied"
                @chip-click="handleChipClick"
              />
            </v-col>
            <v-col
              v-else
              cols="12"
              v-for="(filter, key) in filtersApplied"
              :key="key"
            >
              <v-chip
                class="mr-2"
                label
                outlined
                color="primary"
                @click="handleChipClick(filter)"
                :id="'v-chip-' + key"
              >
                {{
                  `${filter.label}: ${
                    Array.isArray(filter.model)
                      ? getTextWhenFilterIsArray(filter)
                      : filter.model
                  }`
                }}
              </v-chip>
            </v-col>
            <v-col :cols="isMobile ? 12 : 1" class="pl-12 pr-0">
              <v-tooltip location="left">
                <template v-slot:activator="{ props }">
                  <v-btn
                    :icon="showFilters ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                    size="25px"
                    v-bind="props"
                    class="mr-5 white--text"
                    :class="isMobile ? 'to-right' : ''"
                    @click.stop="showFilters = !showFilters"
                    id="v-btn-showfilters"
                  >
                  </v-btn>
                </template>
                {{ !showFilters ? "Mostrar filtros" : "Ocultar filtros" }}
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-expand-transition mode="out-in">
          <v-form
            v-show="showFilters"
            v-model="isFormValid"
            ref="filters-form"
            id="filters-form"
            @submit.prevent="loadUsuarios()"
          >
            <v-row :no-gutters="isMobile">
              <v-col :cols="isMobile ? 12 : 3">
                <v-select
                  label="Emisores"
                  v-model="selectedEmisor"
                  :items="emisor"
                  :readonly="emisor.length === 1"
                  :disabled="financiador.length === 1 || farmacia.length === 1"
                  return-object
                  item-value="id"
                  item-title="value"
                  :clearable="emisor.length !== 1"
                  density="compact"
                  variant="outlined"
                  id="v-select-emisor"
                ></v-select>
              </v-col>
              <v-col :cols="isMobile ? 12 : 3">
                <v-select
                  label="Financiadores"
                  v-model="selectedFinanciador"
                  :readonly="financiador.length === 1"
                  :disabled="emisor.length === 1 || farmacia.length === 1"
                  return-object
                  item-value="id"
                  item-title="value1"
                  :items="financiador"
                  density="compact"
                  variant="outlined"
                  :clearable="financiador.length !== 1"
                  id="v-select-financiador"
                ></v-select>
              </v-col>
              <v-col :cols="isMobile ? 12 : 3">
                <v-select
                  label="Farmacias"
                  v-model="selectedFarmacia"
                  :items="farmacia"
                  :readonly="farmacia.length === 1"
                  :disabled="financiador.length === 1 || emisor.length === 1"
                  return-object
                  item-value="id"
                  item-title="value"
                  density="compact"
                  variant="outlined"
                  :clearable="farmacia.length !== 1"
                  id="v-select-farmacia"
                ></v-select>
              </v-col>
              <v-col :cols="isMobile ? 12 : 3">
                <v-select
                  label="Grupos de usuarios"
                  v-model="selectedGrupo"
                  :items="grupo"
                  return-object
                  item-value="id"
                  item-title="value"
                  clearable
                  density="compact"
                  variant="outlined"
                  id="v-select-grupo"
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="text-right" :no-gutters="isMobile">
              <v-col cols="12">
                <v-tooltip location="left">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      @click="clearSelections"
                      color="grey"
                      v-bind="props"
                      class="mr-5 white--text"
                      size="25px"
                      id="v-icon-limpiarfiltros"
                      >{{ clearFilters }}</v-icon
                    >
                  </template>
                  Limpiar filtros</v-tooltip
                >
                <v-btn
                  color="primary"
                  type="submit"
                  form="filters-form"
                  id="v-btn-aplicar"
                >
                  APLICAR
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-expand-transition>
      </v-container>
    </v-card>
    <!-- data table -->
    <v-card class="elevation-1 mt-2">
      <v-card-text class="py-0 my-0">
        <v-row :no-gutters="isMobile">
          <v-col cols="12" class="mx-0 px-0">
            <v-data-table
              :headers="headers"
              :items="users"
              item-value="name"
              :mobile="isMobile"
              :hide-default-header="isMobile"
              class="pa-3"
              :loading="IsLoading"
              :search="search"
            >
              <template v-slot:[`item.dobleFactorHabilitado`]="{ item }">
                <v-icon
                  small
                  :color="!item.dobleFactorHabilitado ? 'red' : 'green'"
                >
                  {{ !item.dobleFactorHabilitado ? crossIcon : checkIcon }}
                </v-icon> </template
              ><template v-slot:top>
                <v-container>
                  <v-row>
                    <v-col :cols="isMobile ? 9 : 10">
                      <v-text-field
                        v-model="search"
                        :append-icon="searchIcon"
                        label="Buscar"
                        single-line
                        hide-details
                        clearable
                        variant="underlined"
                        id="v-text-field-search"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="2"
                      class="text-right"
                      :class="isMobile ? 'mt-4' : 'mt-2'"
                    >
                      <v-btn
                        @click="openNuevoUsuarioDialog"
                        color="primary"
                        variant="flat"
                        v-if="permisosUsuarios.canCreate"
                        id="v-btn-nuevo"
                        >Nuevo</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip location="left">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      v-bind="props"
                      size="20"
                      @click="openModalRemoveQR(item)"
                      v-if="permisosUsuarios.canRemoveQR"
                      id="v-icon-remove-qr"
                    >
                      {{ qrCodeRemove }}
                    </v-icon>
                  </template>
                  <span>Volver a generar código QR</span>
                </v-tooltip>
                <v-tooltip location="left">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      size="20"
                      @click="openModalRestorePassword(item.userId)"
                      v-bind="props"
                      v-if="permisosUsuarios.canRestorePassword"
                      id="v-icon-cambiarcontrasena"
                      >{{ restoreIcon }}</v-icon
                    >
                  </template>
                  Restaurar contraseña
                </v-tooltip>
                <v-tooltip location="left">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      size="20"
                      @click="openEditUsuario(item)"
                      v-bind="props"
                      v-if="permisosUsuarios.canEdit"
                      id="v-icon-editar"
                      >{{ editIcon }}</v-icon
                    >
                  </template>
                  Editar usuario
                </v-tooltip>
                <v-tooltip location="left">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      size="20"
                      @click="openDeleteAlert(item.userId)"
                      v-bind="props"
                      v-if="permisosUsuarios.canDelete"
                      id="v-icon-eliminar"
                      >{{ deleteIcon }}</v-icon
                    >
                  </template>
                  Eliminar usuario
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- dialog -->
    <v-dialog
      v-if="modalRemoveUserQR"
      v-model="modalRemoveUserQR"
      :max-width="isMobile ? '95%' : '30%'"
      @keydown.esc="closeDialog"
      persistent
    >
      <RemoveQr :userId="userId" @closeDialog="closeDialog()" />
    </v-dialog>
    <v-dialog
      v-if="isOpenEditUsuario"
      v-model="isOpenEditUsuario"
      :max-width="isMobile ? '95%' : '45%'"
      persistent
    >
      <edit-usuario
        :usuarioItemProp="usuarioItem"
        :emisores="emisor"
        :financiadores="financiador"
        :grupos="grupo"
        :farmacias="farmacia"
        @closeDialog="closeDialog($event)"
        @usuarioGuardado="loadUsuarios"
      />
    </v-dialog>
    <v-dialog
      v-if="isOpenModalRestorePassword"
      v-model="isOpenModalRestorePassword"
      :max-width="isMobile ? '95%' : '30%'"
      persistent
    >
      <restore-password :userId="userId" @closeDialog="closeDialog($event)" />
    </v-dialog>
    <v-dialog
      :max-width="isMobile ? '95%' : '30%'"
      v-model="dialogDelete"
      persistent
    >
      <DeleteDialog
        v-if="dialogDelete"
        title="usuario"
        :subtitle="deleteSubtitle"
        :isLoading="isLoadingDelete"
        @eliminar-confirmado="confirmDelete"
        @eliminar-cancelado="closeDeleteDialog"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import EditUsuario from "@/components/modules/usuarios/EditUsuario.vue";
import RemoveQr from "@/components/modules/usuarios/RemoveQr.vue";
import enums from "@/utils/enums/index";
import { mapActions, mapGetters } from "vuex";
import PageHeader from "@/components/layout/PageHeader.vue";
import DeleteDialog from "@/components/shared/DeleteDialog.vue";
import FiltersSelected from "@/components/shared/FiltersSelected.vue";
import RestorePassword from "@/components/shared/RestorePassword.vue";

export default {
  name: "UsuariosView",
  components: {
    EditUsuario,
    RemoveQr,
    PageHeader,
    DeleteDialog,
    FiltersSelected,
    RestorePassword,
  },
  data() {
    PageHeader;
    return {
      content: "",
      headers: [
        {
          title: "Nombre",
          sortable: false,
          key: "userName",
        },
        {
          title: "Email",
          key: "email",
          sortable: false,
        },
        {
          title: "Grupo de permisos",
          key: "grupoUsuario.value",
          sortable: false,
        },
        {
          title: "Doble factor",
          key: "dobleFactorHabilitado",
          sortable: false,
          align: "center",
        },
        { title: "Acciones", key: "actions", align: "end", sortable: false },
      ],
      users: [],
      emisor: [],
      financiador: [],
      grupo: [],
      farmacia: [],
      filtersApplied: [],
      selectedEmisor: null,
      selectedFinanciador: null,
      selectedGrupo: null,
      selectedFarmacia: null,
      usuarioItem: null,
      userToDelete: null,
      search: "",
      searchIcon: enums.icons.SEARCH_ICON,
      checkIcon: enums.icons.CHECK_ICON,
      crossIcon: enums.icons.CROSS_ICON,
      editIcon: enums.icons.EDIT_ICON,
      deleteIcon: enums.icons.DELETE_ICON,
      clearFilters: enums.icons.CLEAR_FILTERS,
      pageTitle: enums.permises.USUARIOS.name,
      goToPath: enums.permises.HOME.path,
      IsLoading: false,
      editUsuarioMode: "nuevo",
      isOpenModalRestorePassword: false,
      isOpenEditUsuario: false,
      dialogDelete: false,
      isLoadingDelete: false,
      showFilters: true,
      isFormValid: false,
      restoreIcon: enums.icons.RESTORE_ICON,
      deleteSubtitle: enums.messages.USUARIOS_DELETE_SUBTITLE,
      userId: null,
      qrCodeRemove: enums.icons.QRCODE_REMOVE,
      modalRemoveUserQR: false,
    };
  },
  computed: {
    ...mapGetters({
      permisos: "menu/permisos",
      isMobile: "menu/isMobile",
    }),
    permisosUsuarios() {
      let canCreate = false;
      let canEdit = false;
      let canDelete = false;
      let canRestorePassword = false;
      let canRemoveQR = false;

      if (this.permisos != null) {
        this.permisos.forEach((x) => {
          switch (x.permisoCodigo) {
            case enums.permises.CREAR_USUARIO.code:
              canCreate = true;
              break;
            case enums.permises.EDITAR_USUARIO.code:
              canEdit = true;
              break;
            case enums.permises.ELIMINAR_USUARIO.code:
              canDelete = true;
              break;
            case enums.permises.RESTORE_PASSWORD.code:
              canRestorePassword = true;
              break;
            case enums.permises.REMOVE_QR.code:
              canRemoveQR = true;
              break;
            default:
              break;
          }
        });
      }
      return { canCreate, canEdit, canDelete, canRestorePassword, canRemoveQR };
    },
  },
  async created() {
    await this.loadCombos();
    this.loadUsuarios();
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
    });
  },

  methods: {
    ...mapActions({
      getUsuarios: "user/getUsuarios",
      getEmisores: "taxonomy/getEmisoresByUser",
      getFinanciadores: "taxonomy/getFinanciadoresByUser",
      getGruposUsuarios: "taxonomy/getGruposUsuariosByUser",
      getFarmacias: "taxonomy/getFarmaciasByUser",
      deleteUsuario: "user/deleteUsuario",
      setAlert: "user/setAlert",
    }),

    async loadCombos() {
      this.emisor = await this.getEmisores();
      if (this.emisor.length === 1) this.selectedEmisor = this.emisor[0];

      this.financiador = await this.getFinanciadores();
      if (this.financiador.length === 1)
        this.selectedFinanciador = this.financiador[0];

      this.grupo = await this.getGruposUsuarios();

      this.farmacia = await this.getFarmacias();
      if (this.farmacia.length === 1) this.selectedFarmacia = this.farmacia[0];
    },

    async loadUsuarios() {
      this.IsLoading = true;
      this.customizeFiltersApplied();
      const payload = {
        emisorId: this.selectedEmisor?.id,
        financiadorId: this.selectedFinanciador?.id,
        grupoUsuId: this.selectedGrupo?.id,
        farmaciaId: this.selectedFarmacia?.id,
      };
      const response = await this.getUsuarios(payload);
      this.users = response;
      this.IsLoading = false;
      this.showFilters = false;
    },
    handleChipClick() {
      this.showFilters = true;
    },
    customizeFiltersApplied() {
      const updateFilter = (key, label, model) => {
        const existingFilterIndex = this.filtersApplied.findIndex(
          (filter) => filter.key === key
        );
        if (model) {
          if (existingFilterIndex !== -1) {
            this.filtersApplied[existingFilterIndex] = { key, label, model };
          } else {
            this.filtersApplied.push({ key, label, model });
          }
        } else if (existingFilterIndex !== -1) {
          this.filtersApplied.splice(existingFilterIndex, 1);
        }
        if (
          !this.selectedEmisor &&
          !this.selectedFinanciador &&
          !this.selectedGrupo &&
          !this.selectedFarmacia
        ) {
          this.filtersApplied.push({ key, label, model: "Todos" });
        }
      };
      updateFilter("emisorNom", "Emisor", this.selectedEmisor?.value);
      updateFilter(
        "financiadorNom",
        "Financiador",
        this.selectedFinanciador?.value1
      );
      updateFilter("FarmaciaNom", "Farmacia", this.selectedFarmacia?.value);
      updateFilter("GrupoNom", "Grupo", this.selectedGrupo?.value);

      this.showFilters = true;
    },
    openEditUsuario(item) {
      this.isOpenEditUsuario = true;
      this.usuarioItem = item;
    },
    closeDialog(reload) {
      this.isOpenEditUsuario = false;
      this.isOpenModalRestorePassword = false;
      this.modalRemoveUserQR = false;
      this.usuarioItem = null;
      if (reload) {
        this.loadUsuarios();
      }
    },
    clearSelections() {
      this.selectedEmisor = null;
      this.selectedFinanciador = null;
      this.selectedGrupo = null;
      this.selectedFarmacia = null;
      this.filtersApplied = [];
    },
    openNuevoUsuarioDialog() {
      this.isOpenEditUsuario = true;
      this.editUsuarioMode = "nuevo";
    },
    openModalRestorePassword(id) {
      this.isOpenModalRestorePassword = true;
      this.userId = id;
    },
    openDeleteAlert(id) {
      this.dialogDelete = true;
      this.userToDelete = id;
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
    },
    async confirmDelete() {
      this.isLoadingDelete = true;
      try {
        const response = await this.deleteUsuario(this.userToDelete);
        if (response?.status == 200) {
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.dialogDelete = false;
          this.loadUsuarios();
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoadingDelete = false;
    },
    openModalRemoveQR(item) {
      this.modalRemoveUserQR = true;
      this.userId = item.userId;
    },
  },
};
</script>
<style scoped></style>
